import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "assets/Logo.svg";
import Layout from "./layout";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import mobileMenuTheme from "./headerMobileStyle";

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

    return (
        <>
            <MobileContent>
                <Menu
                    isOpen={isMenuOpen}
                    onOpen={() => setIsMenuOpen(true)}
                    onClose={() => setIsMenuOpen(false)}
                    right
                    styles={Object.assign(mobileMenuTheme)}
                    pageWrapId="page-wrap"
                    outerContainerId="outer-container"
                >
                    <HeaderLink onClick={() => setIsMenuOpen(false)} to="/">
                        Home
                    </HeaderLink>
                    <HeaderLink onClick={() => setIsMenuOpen(false)} to="/projects">
                        Projekte
                    </HeaderLink>
                    <HeaderLink onClick={() => setIsMenuOpen(false)} to="/about-us">
                        Über Uns
                    </HeaderLink>
                    <Logo />
                </Menu>
            </MobileContent>

            <Layout minHeight="200px" minHeightMobile="150px" background="transparent" position="absolute" positionMobile="absolute">
                <Content>
                    <StyledLogo />
                    <Navigation>
                        <HeaderLink to="/">Home</HeaderLink>
                        <HeaderLink to="/projects">Projekte</HeaderLink>
                        <HeaderLink to="/about-us">Über Uns</HeaderLink>
                    </Navigation>
                </Content>
            </Layout>
        </>
    );
};

export default Header;

const Content = styled.div`
    background: transparent;
    flex-direction: column;
    display: none;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        grid-column: 2 / span 23;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: flex;
    }
`;

const Navigation = styled.div`
    display: flex;
    flex-direction: row;
    background: ${({ theme }) => theme.palette.blue};
`;

const HeaderLink = styled(NavLink)`
    padding: 20px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.white};
    text-decoration: none;

    &.active {
        ${({ theme }) => theme.borderBottom};
    }

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        padding: 20px 40px;
    }
`;

const StyledLogo = styled(Logo)`
    display: none;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        display: block;
    }
`;

const MobileContent = styled.div`
    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        display: none;
    }
`;
