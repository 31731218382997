import React from "react";
import { Layout, ServiceItem } from "common";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import useDeviceDetect, { DeviceType } from "hooks/device";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { SlideLink } from "./stage";
import Bagger from "assets/bagger.webp";
import Transport from "assets/transport.webp";
import Muldenservice from "assets/mulde.webp";

SwiperCore.use([Navigation, Autoplay]);

export interface SliderContent {
    img: string;
    headline: string;
    link: string;
}

const sliderContent: SliderContent[] = [
    {
        img: Bagger,
        headline: "Weiche Untergründe",
        link: "/projects",
    },
    {
        img: Transport,
        headline: "Transporte an abgelegene Orte",
        link: "/projects",
    },
    {
        img: Muldenservice,
        headline: "Muldenservice",
        link: "/projects",
    },
];

const Projects: React.FC = () => {
    const device = useDeviceDetect();

    return (
        <Layout minHeight="50vh" padding="30px 0" minHeightMobile="50vh">
            <Content>
                <HeadlineContainer>
                    <Headline>Spezial Service</Headline>
                    <ButtonContainer>
                        <ChevronLeft className="prev-el" />
                        <ChevronRight className="next-el" />
                    </ButtonContainer>
                </HeadlineContainer>
                <CustomSwiper
                    slidesPerView={device === DeviceType.DESKTOP ? 3 : device === DeviceType.MOBILE ? 1.3 : 4}
                    navigation={{
                        enabled: true,
                        nextEl: ".next-el",
                        prevEl: ".prev-el",
                    }}
                    spaceBetween={30}
                    autoplay={{
                        delay: 5000,
                    }}
                    loop
                >
                    {sliderContent.map(({ headline, img, link }, index) => (
                        <SwiperSlide key={`${headline}__${index}`}>
                            <ServiceItem img={img} service={headline} link={link} />
                        </SwiperSlide>
                    ))}
                </CustomSwiper>
                <LayoutRight>
                    <SlideLink color="black" to="/projects">
                        Mehr dazu
                    </SlideLink>
                </LayoutRight>
            </Content>
        </Layout>
    );
};

export default Projects;

const Headline = styled.h1`
    ${({ theme }) => theme.fonts.headline};
`;

const Content = styled.div`
    grid-column: 3 / span 20;
`;

export const CustomSwiper = styled(Swiper)`
    margin: 0;
    position: relative;

    .swiper-button-prev {
        display: none;
    }

    .swiper-button-next {
        display: none;
    }
`;

const ButtonContainer = styled.div`
    svg {
        display: none;
        background: ${({ theme }) => theme.palette.white};
        font-size: 40px;
        border-radius: 50%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 0 15px;

        @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
            display: initial;
        }
    }
`;

const HeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const LayoutRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 20px 0 40px 0;
    width: 100%;
`;
