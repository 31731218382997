import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from './theme';
import App from 'App';
import 'swiper/css/bundle';
import './index.css';
import GA4React from 'ga-4-react';

const container = document.getElementById('root')!;
const root = createRoot(container);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    position: relative;
    font-family: 'Inter', sans-serif;
  }
`;

root.render(
    <React.StrictMode>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

const ga4react = new GA4React(process.env.REACT_APP_GA4_TRACKING_ID || '');
ga4react.initialize().then().catch();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
