import React from 'react';

export enum DeviceType {
    MOBILE,
    DESKTOP,
    DESKTOP_4K,
}

const breakpoints = {
    mobile: 1200,
    desktop: 1920,
};

const useDeviceDetect = () => {
    const setDeviceState = (width: number): DeviceType => {
        if (width < breakpoints.mobile) {
            return DeviceType.MOBILE;
        } else if (width > breakpoints.desktop) {
            return DeviceType.DESKTOP_4K;
        }
        return DeviceType.DESKTOP;
    };

    const [isDevice, setIsDevice] = React.useState<DeviceType>(setDeviceState(window.innerWidth));

    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setIsDevice(setDeviceState(window.innerWidth));
        });
    }, []);

    return isDevice;
};

export default useDeviceDetect;
