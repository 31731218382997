import { Layout } from 'common';
import React from 'react';
import styled from 'styled-components';

import IconServiceGridItem from './iconServiceGridItem';
import Truck from 'assets/services/truck.png';
import Crane from 'assets/services/crane.png';
import Brick from 'assets/services/brick.png';
import SpecialService from 'assets/services/specialservice.png';
import Winterservice from 'assets/services/winterservice.png';
import { CategoryTypes } from 'pages/projects/projects';
import Baustoffhandel from 'assets/baustoffhandel_.webp';
export interface Service {
    icon: string;
    headline: string;
    text: string;
    link: string;
}

const services: Service[] = [
    {
        icon: Truck,
        headline: 'Transporte',
        text: 'Für Transporte auch in abgelegenere Orte und auf Bergstraßen im Raum Salzburgerland sind wir für Sie der richtige Ansprechpartner.',
        link: `/projects?category=${CategoryTypes.TRANSPORT}`,
    },
    {
        icon: Crane,
        headline: 'Kranarbeiten',
        text: 'Wir heben mit unseren Kranfahrzeugen fast alle Güter dorthin, wo Sie es wünschen.',
        link: `/projects?category=${CategoryTypes.CRANE}`,
    },
    {
        icon: Brick,
        headline: 'Baustoffhandel',
        text: 'Wir verkaufen Baustoffe, Bedachungsartikel, Holz, Dämmstoffe, Baustähle und vieles mehr.',
        link: `/projects?category=${CategoryTypes.BUILDING_MATERIAL}`,
    },
    {
        icon: Winterservice,
        headline: 'Winterdienst',
        text: 'Wir machen den Weg frei bei Schnee und Eis, damit alle sicher nachhause kommen.',
        link: `/projects?category=${CategoryTypes.WINTERSERVICE}`,
    },
    {
        icon: SpecialService,
        headline: 'Bauschuttmulden Service',
        text: 'Mit unseren universal einsetzbaren Kraftfahrzeugen liefern wir Ihnen Schüttgut aller Art zur Baustelle.',
        link: `/projects?category=${CategoryTypes.MULDEN}`,
    },
];

const Services: React.FC = () => {
    return (
        <Layout
            padding="50px 0 0 0"
            minHeight="50vh"
            minHeightMobile="30vh"
        >
            <Content>
                <Headline>Krempler Baustoff - Handel</Headline>
                <Container>
                    <Image
                        src={Baustoffhandel}
                        alt=""
                    />
                    <Paragraph>
                        Vom Heimwerkerprojekt bis zur Großbaustelle – wir verwirklichen Ihre Visionen.
                        <br/>

                        Wer wir sind:
                        <ul>
                            <li>Familiengeführt, ehrlich, kompetent und qualitätsorientiert</li>
                        </ul>

                        Was wir bieten:
                        <ul>
                            <li>Baustoffe, Winterdienst, Bauschuttentsorgung</li>
                            <li>Transport und fachkundige Beratung</li>
                        </ul>

                        Unser Versprechen:
                        <ul>
                            <li>Pünktliche Lieferung hochwertiger Markenware</li>
                            <li>Kundenorientierter Service und effiziente Ressourcennutzung</li>
                            <li>Schneller, zuverlässiger Lieferservice</li>
                        </ul>

                        <b>Alexander Krempler</b> – Ihr Partner vom Fundament bis zum Dach.
                        <br/>
                        Kontaktieren Sie uns und erleben Sie den Unterschied!
                    </Paragraph>
                </Container>
                <ServicesGrid>
                    {services.map((service, index) => (
                        <IconServiceGridItem
                            key={`${service.headline}__${index}`}
                            service={service}
                            delay={index + 2}
                            link={service.link}
                        />
                    ))}
                </ServicesGrid>
            </Content>
        </Layout>
    );
};

export default Services;

const Content = styled.div`
    grid-column: 2 / span 21;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        grid-column: 3 / span 20;
        grid-template-columns: repeat(24, 1fr);
    }
`;

const Headline = styled.h1`
    ${({ theme }) => theme.fonts.headline};
    grid-column: 1 / span 24;
    letter-spacing: 7px;
`;

const Paragraph = styled.p`
    ${({ theme }) => theme.fonts.paragraph}
`;

const ServicesGrid = styled.div`
    grid-column: 3 / span 19;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-top: 50px;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        margin-top: 100px;
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
        gap: 100px;
        grid-template-columns: repeat(3, 1fr);
    }
`;

const Image = styled.img`
    height: auto;
    width: 100%;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        height: 400px;
        width: 600px;
        margin-right: 50px;
    }
`;

const Container = styled.div`
    display: flex;
    grid-column: 1 / span 24;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        flex-direction: row;
    }
`;
