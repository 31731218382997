import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";

interface VizDetectorProps {
    children?: React.ReactNode;
    delay: number;
}

const VizDetector: React.FC<VizDetectorProps> = ({ children, delay }) => {
    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    const onChange = () => {
        if (!isVisible) {
            setIsVisible(true);
        }
    };

    return (
        <VisibilitySensor onChange={onChange}>
            <Content delay={delay} isVisible={isVisible}>
                {children}
            </Content>
        </VisibilitySensor>
    );
};

export default VizDetector;

interface ContentProps {
    delay: number;
    isVisible: boolean;
}

const Content = styled.div<ContentProps>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(${({ isVisible }) => (!isVisible ? "50px" : 0)});
    transition: ${({ delay }) => `${delay}s`} ease opacity, ${({ delay }) => `${delay}s`} ease transform;
`;
