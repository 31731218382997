import { Layout } from "common";
import Legals from "assets/legals.webp";

const Stage: React.FC = () => {
    return (
        <Layout
            minHeight="55vh"
            minHeightMobile="55vh"
            background={`linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${Legals})`}
        ></Layout>
    );
};

export default Stage;
