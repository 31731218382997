import React from 'react';
import Layout from '../../common/layout';
import Transporte from 'assets/transporter.webp';
import Winterdienst from 'assets/winterdienst.webp';
import Baustoffhandel from 'assets/baustoffhandel.webp';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Autoplay } from 'swiper';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CategoryTypes } from 'pages/projects/projects';

SwiperCore.use([Autoplay]);

interface SliderContent {
    background: string;
    headline: string;
    paragraph: string;
    link: string;
}

const sliderContent: SliderContent[] = [
    {
        background: Baustoffhandel,
        headline: 'Baustoffhandel',
        paragraph: 'Wir verkaufen Baustoffe, Bedachungsartikel, Holz, Dämmstoffe, Baustähle und vieles mehr.',
        link: `/projects?category=${CategoryTypes.BUILDING_MATERIAL}`,
    },
    {
        background: Transporte,
        headline: 'Transporte',
        paragraph:
            'Wir bieten Ihnen die richtige Antwort für ihren Transport in Salzburg und Umgebung mit unserem eigenen Fuhrpark. Für weitere Details kontaktieren Sie uns doch direkt unter Kontakt.',
        link: `/projects?category=${CategoryTypes.TRANSPORT}`,
    },
    {
        background: Winterdienst,
        headline: 'Winterdienst',
        paragraph: 'Wir machen den Weg frei bei Schnee und Eis, damit alle sicher nachhause kommen.',
        link: `/projects?category=${CategoryTypes.WINTERSERVICE}`,
    },
];

const Stage: React.FC = () => {
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);

    return (
        <Layout minHeightMobile="30vh">
            <CustomSwiper
                autoplay={{
                    delay: 5000,
                }}
                loop
                onSlideChange={(event) => setCurrentIndex(event.realIndex)}
            >
                {sliderContent.map(({ headline, paragraph, link, background }, index) => (
                    <SwiperSlide key={`${headline}__${link}__${index}`}>
                        <SlideGrid background={background}>
                            <SlideContent transition={currentIndex === index}>
                                <SlideHeadline>{headline}</SlideHeadline>
                                <SlideParagraph>{paragraph}</SlideParagraph>
                                <SlideLink
                                    to={link}
                                    color="white"
                                >
                                    Mehr dazu
                                </SlideLink>
                            </SlideContent>
                        </SlideGrid>
                    </SwiperSlide>
                ))}
            </CustomSwiper>
        </Layout>
    );
};

export default Stage;

const CustomSwiper = styled(Swiper)`
    height: 100vh;
    margin: 0;
    grid-column: 1 / span 24;
    width: 100%;
    z-index: 0;

    .swiper-pagination {
        left: 0px;
        top: 60% !important;
        right: unset;
    }

    .swiper-pagination-bullet {
        width: 12.5px;
        height: 12.5px;
        background: ${({ theme }) => theme.palette.white};
        margin: 15px 0 !important;
    }
`;

interface SlideGridProps {
    background: string;
}

const SlideGrid = styled.div<SlideGridProps>`
    color: ${({ theme }) => theme.palette.white};
    height: 100%;
    width: 100%;
    user-select: none;
    background: ${({ background }) => `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${background})`};
    z-index: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(3, 1fr);
`;

interface SlideContentProps {
    transition: boolean;
}

const SlideContent = styled.div<SlideContentProps>`
    grid-row: 2 / span 1;
    grid-column: 3 / span 20;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 1s ease transform, 1s ease opacity;
    transform: ${({ transition }) => (transition ? 'translateY(0)' : 'translateY(100px)')};
    opacity: ${({ transition }) => (transition ? 1 : 0)};

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        grid-column: 3 / span 10;
    }
`;

const SlideHeadline = styled.h1`
    font-size: 38px;
    margin: 0;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        font-size: 100px;
        margin: 0 0 0 -8px;
    }
`;

const SlideParagraph = styled.p`
    ${({ theme }) => theme.fonts.paragraph};

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        margin-top: 0px;
    }
`;

interface SlideLinkProps {
    color: 'white' | 'black';
}

export const SlideLink = styled(Link)<SlideLinkProps>`
    color: ${({ theme, color }) => theme.palette[color]};
    text-decoration: none;
    ${({ theme }) => theme.borderBottom};
    width: 100px;
    text-transform: uppercase;
`;
