import React from "react";
import Layout from "./layout";
import { ReactComponent as Logo } from "assets/Logo.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
    return (
        <>
            <Layout minHeight="30vh" minHeightMobile="35vh" background="rgba(35, 95, 149, 70%)">
                <Content>
                    <Logo />
                    <Links>
                        <Link to="/impressum">Impressum</Link>
                        <Link to="/agb">AGB</Link>
                        <Link to="/data-security">Datenschutz</Link>
                    </Links>
                </Content>
            </Layout>
        </>
    );
};

export default Footer;

const Content = styled.div`
    grid-column: 1 / span 24;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Links = styled.div`
    display: flex;
    flex-direction: row;

    a {
        padding: 30px 20px 0;
        text-transform: uppercase;
        text-decoration: none;
        color: ${({ theme }) => theme.palette.white};
    }
`;
