import React from "react";
import styled from "styled-components";
import Layout from "./layout";

const Maps: React.FC = () => {
    return (
        <Layout minHeight="40vh" minHeightMobile="40vh">
            <Content>
                <iframe
                    title="Google Maps Alex"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2679.62149842418!2d13.064649915995668!3d47.80817247919851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4776909cc998c483%3A0xae7cbfa94da4c38a!2sF%C3%BCrbergstra%C3%9Fe%2027%2C%205020%20Salzburg!5e0!3m2!1sde!2sat!4v1657625600083!5m2!1sde!2sat"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen={false}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </Content>
        </Layout>
    );
};

export default Maps;

const Content = styled.div`
    grid-column: 1 / span 24;

    iframe {
        width: 100%;
    }
`;
