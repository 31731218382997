import Stage from './stage';
import { Layout } from 'common';
import React from 'react';
import styled from 'styled-components';
import Transport from 'assets/transport.webp';
import Baustoffhandel from 'assets/baustoffhandel.webp';
import { BrowserView, MobileView } from 'common/device';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import Cont1 from 'assets/services/BauschauttMuldenService/cont-1.webp';
import Cont2 from 'assets/services/BauschauttMuldenService/cont-2.webp';
import Cont3 from 'assets/services/BauschauttMuldenService/cont-3.webp';
import Cont4 from 'assets/services/BauschauttMuldenService/cont-4.webp';
import Kranarbeiten1 from 'assets/services/Kranarbeiten/kranarbeiten_1.webp';
import Kranarbeiten2 from 'assets/services/Kranarbeiten/kranarbeiten_2.webp';
import Kranarbeiten3 from 'assets/services/Kranarbeiten/kranarbeiten_3.webp';
import Kranarbeiten4 from 'assets/services/Kranarbeiten/kranarbeiten_4.webp';
import Kranarbeiten5 from 'assets/services/Kranarbeiten/kranarbeiten_5.webp';
import Kranarbeiten6 from 'assets/services/Kranarbeiten/kranarbeiten_6.webp';
import Kranarbeiten7 from 'assets/services/Kranarbeiten/kranarbeiten_7.webp';
import Kranarbeiten8 from 'assets/services/Kranarbeiten/kranarbeiten_8.webp';
import Kranarbeiten9 from 'assets/services/Kranarbeiten/kranarbeiten_9.webp';

export enum CategoryTypes {
    ALL = 'Alle',
    CRANE = 'Kranarbeiten',
    TRANSPORT = 'Transporte',
    WINTERSERVICE = 'Winterdienst',
    BUILDING_MATERIAL = 'Baustoffe',
    MULDEN = 'Muldenservice',
}

interface ProjectsType {
    img: string;
    category: CategoryTypes;
}

const projects: ProjectsType[] = [
    {
        img: Transport,
        category: CategoryTypes.TRANSPORT,
    },
    {
        img: Cont1,
        category: CategoryTypes.MULDEN,
    },
    {
        img: Cont2,
        category: CategoryTypes.MULDEN,
    },
    {
        img: Cont3,
        category: CategoryTypes.MULDEN,
    },
    {
        img: Cont4,
        category: CategoryTypes.MULDEN,
    },
    {
        img: Kranarbeiten1,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten2,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten3,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten4,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten5,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten6,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten7,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten8,
        category: CategoryTypes.CRANE,
    },
    {
        img: Kranarbeiten9,
        category: CategoryTypes.CRANE,
    },
    {
        img: Baustoffhandel,
        category: CategoryTypes.BUILDING_MATERIAL,
    },
];

const Projects: React.FC = () => {
    const [currentCategory, setCurrentCategory] = React.useState<string>(CategoryTypes.ALL);
    const [currentProjects, setCurrentProjects] = React.useState<ProjectsType[]>(projects);
    const [searchParams] = useSearchParams();

    const onDropdownSelect = async (event: SelectChangeEvent): Promise<void> => {
        const cat = event.target.value as string;
        onClick(cat);
    };

    const onClick = (cat: string) => {
        setCurrentCategory(cat);

        if (cat === CategoryTypes.ALL) {
            setCurrentProjects(projects);
        } else {
            setCurrentProjects(projects.filter(({ category }) => cat === category));
        }
    };

    React.useEffect(() => {
        const _category = searchParams.get('category');

        if (_category) {
            setCurrentCategory(_category);
            setCurrentProjects(projects.filter(({ category }) => _category === category));
        }
    }, [searchParams]);

    return (
        <>
            <Stage />
            <Layout
                padding="50px 0 100px 0"
                paddingMobile="50px 0 100px 0"
                minHeight="50vh"
                minHeightMobile="30vh"
            >
                <Content>
                    <Headline>Unsere Leistungen</Headline>
                    <Paragraph>
                        Machen Sie sich selbst ein Bild von unserer Arbeit. Von Kranarbeiten, Transporte, Winterdienste, Baustoffhandel bis zum
                        Bauschuttmulden Service im Raum Salzburg und Salzburger Land sind wir gerne für Sie da. Ein paar Auszüge unserer Aufträge und
                        Tätigkeiten sehen Sie hier.
                    </Paragraph>
                    <BrowserView>
                        <Categories>
                            {Object.keys(CategoryTypes).map((key: string) => {
                                const category = CategoryTypes[key as keyof typeof CategoryTypes];
                                return (
                                    <Category
                                        key={key}
                                        className={category === currentCategory ? 'selected' : ''}
                                        onClick={() => onClick(category)}
                                    >
                                        {category}
                                    </Category>
                                );
                            })}
                        </Categories>
                    </BrowserView>
                    <MobileView>
                        <CustomFormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Service</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentCategory}
                                label="category"
                                onChange={onDropdownSelect}
                            >
                                {Object.keys(CategoryTypes).map((key: string) => {
                                    const category = CategoryTypes[key as keyof typeof CategoryTypes];

                                    return (
                                        <MenuItem
                                            value={category}
                                            key={`${category}_mobile`}
                                        >
                                            {category}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </CustomFormControl>
                    </MobileView>
                    <ProjectGrid>
                        {currentProjects.map(({ img, category }, index) => (
                            <Image
                                loading="lazy"
                                key={`Alex_Krempler_${category}_${index}`}
                                src={img}
                            />
                        ))}
                    </ProjectGrid>
                </Content>
            </Layout>
        </>
    );
};

export default Projects;

const Content = styled.div`
    grid-column: 3 / span 20;
`;

const Headline = styled.h1`
    ${({ theme }) => theme.fonts.headline}
`;

const Paragraph = styled.p`
    ${({ theme }) => theme.fonts.paragraph}
    width: 100%;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        width: 50%;
    }
`;

const ProjectGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 25px;
    gap: 30px;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        margin-top: 50px;
        gap: 50px;
        grid-template-columns: repeat(3, 1fr);
    }
`;

const Image = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        height: 400px;
    }
`;

const Categories = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 50px 0;
`;

const Category = styled.button`
    all: unset;
    text-decoration: none;
    min-width: 80px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 20px;
    padding: 0 10px;
    cursor: pointer;

    &.selected {
        ${({ theme }) => theme.borderBottom};
    }
`;

const CustomFormControl = styled(FormControl)`
    margin-top: 25px !important;
`;
