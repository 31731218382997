import { useEffect } from "react";
import { useLocation } from "react-router";

interface ScrollToTopProps {
    children?: React.ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        if (!window.location.search.includes("category")) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{children}</>;
};

export default ScrollToTop;
