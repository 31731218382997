import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { SlideLink } from "pages/home/stage";

export interface Service {
    icon: string;
    headline: string;
    text: string;
}

interface ServiceItemProps {
    service: Service;
    link?: string;
    minWidthMobile?: string;
}

const IconServiceItem: React.FC<ServiceItemProps> = ({ service, link, minWidthMobile }) => {
    let navigate = useNavigate();

    return (
        <SlideContent minWidthMobile={minWidthMobile} isClickable={link ? true : false} onClick={() => link && navigate(link, { replace: true })}>
            <SlideContentContainer>
                <IconContainer>
                    <Icon src={service.icon} alt="" />
                </IconContainer>
                <Headline>{service.headline}</Headline>
                <Paragraph>{service.text}</Paragraph>
            </SlideContentContainer>
            <CustomSiteLink color="black" to={link ? link : "/"}>
                Mehr dazu
            </CustomSiteLink>
        </SlideContent>
    );
};

export default IconServiceItem;

const IconContainer = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    padding: 25px;
    border-radius: 50%;
    background: rgb(203, 225, 242);
`;

const Icon = styled.img`
    width: 100px;
    height: auto;
`;

interface SlideContentProps {
    isClickable: boolean;
    minWidthMobile?: string;
}

const Headline = styled.h2`
    ${({ theme }) => theme.fonts.headline2};
    margin: 20px 0;
    text-align: center;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        height: 50px;
    }
`;

const Paragraph = styled.p`
    ${({ theme }) => theme.fonts.paragraph};
    margin: 0;
    text-align: center;
`;

const CustomSiteLink = styled(SlideLink)`
    position: relative;
    transform: translateY(-80px);

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        position: absolute;
        bottom: 50px;
        transform: translateY(0px);
    }
`;

const SlideContent = styled.div<SlideContentProps>`
    width: ${({ minWidthMobile }) => (minWidthMobile ? minWidthMobile : "100%")};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "normal")};
    ${({ theme }) => theme.boxShadow};
    padding: 20px 10px;
    margin: 40px 0;
    min-width: 300px;
    height: 320px;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        width: 100%;
        margin: 20px 0;
    }
`;

const SlideContentContainer = styled.div`
    transform: translateY(-100px);

    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
    position: relative;

    svg {
        font-size: 30px;
    }
`;
