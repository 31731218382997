import { css, SimpleInterpolation, FlattenInterpolation } from "styled-components/macro";

interface Breakpoints {
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
}

interface Palette {
    blue: string;
    greyBlue: string;
    lightBlue: string;
    white: string;
    black: string;
    blue2: string;
}

interface Fonts {
    paragraph: FlattenInterpolation<unknown>;
    headline: FlattenInterpolation<unknown>;
    headline2: FlattenInterpolation<unknown>;
    headline3: FlattenInterpolation<unknown>;
    headlineXL: FlattenInterpolation<unknown>;
}

interface Theme {
    breakpoints: Breakpoints;
    palette: Palette;
    fonts: Fonts;
    boxShadow: SimpleInterpolation;
    borderBottom: SimpleInterpolation;
}

const breakpoints: Breakpoints = {
    sm: 600,
    md: 1200,
    lg: 1460,
    xl: 1600,
    xxl: 2000,
};

const theme: Theme = {
    breakpoints,
    palette: {
        blue: "rgba(35, 95, 149, 70%)",
        greyBlue: "rgba(35, 95, 149, 30%)",
        lightBlue: "#339CD5",
        white: "#FFFFFF",
        black: "#000000",
        blue2: "#235F95",
    },
    fonts: {
        paragraph: css`
            font-size: 18px;
        `,
        headline: css`
            font-size: 38px;

            @media screen and (min-width: 1200px) {
                font-size: 60px;
            }
        `,
        headline2: css`
            font-size: 18px;
            line-height: 32px;
            color: rgba(35, 95, 149, 70%);

            @media screen and (min-width: 1200px) {
                font-size: 30px;
                line-height: 32px;
            }
        `,
        headline3: css`
            font-size: 32px;
            line-height: 32px;
        `,
        headlineXL: css`
            font-size: 38px;

            @media screen and (min-width: 1200px) {
                font-size: 100px;
            }
        `,
    },
    boxShadow: css`
        box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
    `,
    borderBottom: css`
        border-bottom: 5px solid #339cd5;
    `,
};

export default theme;
