import { VizDetector } from "common";
import IconServiceItem, { Service } from "common/iconServiceItem";
import React from "react";

interface ServiceGridItemProps {
    service: Service;
    delay: number;
    link: string;
}

const IconServiceGridItem: React.FC<ServiceGridItemProps> = ({ service, delay, link }) => {
    return (
        <VizDetector delay={delay}>
            <IconServiceItem service={service} link={link} />
        </VizDetector>
    );
};

export default IconServiceGridItem;
