import React from "react";
import Stage from "./stage";
import Layout from "../../common/layout";
import styled from "styled-components";

const DataSecurity: React.FC = () => {
    return (
        <>
            <Stage />
            <Layout>
                <Content>
                    <h1>Erklärung zur Informationspflicht</h1>
                    <h2>Datenschutzerklärung</h2>
                    <p>
                        In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
                        Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der gesetzlichen Bestimmungen
                        (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).
                        <br /> <br />
                        Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende
                        der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.
                        <br /> <br />
                        Kontakt mit uns <br />
                        Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email kontaktieren, dann werden die von Ihnen
                        an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für sechs Monate
                        bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.
                        <br /> <br />
                        Cookies <br />
                        Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf
                        Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu
                        gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser
                        beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie
                        über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die
                        Funktionalität unserer Website eingeschränkt sein.
                        <br /> <br />
                        Google Maps <br />
                        Unsere Website verwendet Funktionen des Webkartendienstes „Google Maps“. Der Dienstanbieter dieser Funktion ist:
                        <br /> <br />
                        Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland. Tel: +353 1 543 1000 Im Zuge der Nutzung von Google Maps
                        ist es notwendig Ihre IP-Adresse zu speichern und zu verarbeiten. Google überträgt in der Regel an einen Server in den USA und
                        speichert die Daten dort. Die Verarbeitung geschieht durch den Diensteanbieter (oben genannt), der Betreiber dieser Homepage
                        hat keinen Einfluss auf die Übertragung der Daten.
                        <br /> <br />
                        Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit f
                        (berechtigtes Interesse) der DSGVO. Die Nutzung von Google Maps erhöht die Auffindbarkeit der Orte, welche auf unserer
                        Webseite bereitgestellt werden.
                        <br /> <br />
                        Weitere Informationen über den Umgang mit Nutzerdaten des Diensteanbieters „Google“ können Sie der Datenschutzerklärung
                        entnehmen:
                        <br /> <br />
                        https://policies.google.com/privacy?hl=de .
                        <br /> <br />
                        Google verarbeitet die Daten auch in den USA, hat sich jedoch dem EU-US Privacy-Shield unterworfen.
                        <br /> <br />
                        https://www.privacyshield.gov/EU-US-Framework
                        <br /> <br />
                        Google Fonts Unsere Website verwendet Schriftarten von „Google Fonts“. Der Dienstanbieter dieser Funktion ist:
                        <br /> <br />
                        Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland Tel: +353 1 543 1000
                        <br /> <br />
                        Beim Aufrufen dieser Webseite lädt Ihr Browser Schriftarten und speichert diese in den Cache. Da Sie, als Besucher der
                        Webseite, Daten des Dienstanbieters empfangen kann Google unter Umständen Cookies auf Ihrem Rechner setzen oder analysieren.
                        <br /> <br />
                        Die Nutzung von „Google-Fonts“ dient der Optimierung unserer Dienstleistung und der einheitlichen Darstellung von Inhalten.
                        Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                        <br /> <br />
                        Weitere Informationen zu Google Fonts erhalten Sie unter folgendem Link:
                        <br /> <br />
                        https://developers.google.com/fonts/faq Weitere Informationen über den Umgang mit Nutzerdaten von Google können Sie der
                        Datenschutzerklärung entnehmen:
                        <br /> <br />
                        https://policies.google.com/privacy?hl=de . Google verarbeitet die Daten auch in den USA, hat sich jedoch dem EU-US
                        Privacy-Shield unterworfen.
                        <br /> <br />
                        https://www.privacyshield.gov/EU-US-Framework
                        <br /> <br />
                        Ihre Rechte als Betroffener Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein
                        Recht auf: Auskunft Löschung der Daten Berichtigung der Daten Übertragbarkeit der Daten Wiederruf und Widerspruch zur
                        Datenverarbeitung Einschränkung Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das
                        Datenschutzrecht passiert sind, so haben Sie die Möglichkeit sich bei uns (krempler.alexander@gmail.com) oder der
                        Datenschutzbehörde zu beschweren.
                        <br /> <br />
                        Sie erreichen uns unter folgenden Kontaktdaten: <br />
                        Webseitenbetreiber: Alexander Krempler <br />
                        Telefonnummer: +43 (0) 664 5490464 <br />
                        Email: krempler.alexander@gmail.com <br />
                        Quelle: Rechtsanwalt finden in Kooperation mit Geld zurück Online Glücksspiel
                    </p>
                </Content>
            </Layout>
        </>
    );
};

export default DataSecurity;

const Content = styled.div`
    padding: 50px 0;
    grid-column: 3 / span 20;
`;
