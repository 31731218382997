import { Layout } from "common";
import styled from "styled-components";
import Stage from "./stage";
import Alex from "assets/alex.webp";
import Contact from "common/contact";

const AboutUs: React.FC = () => {
    return (
        <>
            <Stage />
            <Layout paddingMobile="75px 0">
                <Content>
                    <TextContainer>
                        <Headline>Alexander Krempler</Headline>
                        <Paragraph>
                            Sehr geehrte Damen und Herren, ich darf mich vorstellen. Mein Name ist Alexander Josef Krempler, Geschäftsführer meines
                            Unternehmens und Familienvater. Ich bin in einer kleinen aber feinen Landwirtschaft glücklich aufgewachsen und das prägt
                            einen in der Denk und Arbeitsweise. Ich habe mich 2019 entschieden mein Unternehmen Baustoffhandel Alexander Krempler zu
                            gründen, um das zu machen was ich am besten kann.
                        </Paragraph>
                        <SubHeadline>Familienunternehmen mit Herz</SubHeadline>
                        <Paragraph>
                            Meine Frau ist die gute Seele die mich tatkräftig als Bürokraft unterstützt und alles im Hintergrund regelt, sowie die
                            Mutter unserer 3 Kinder ist.
                        </Paragraph>
                        <Paragraph>
                            Guter Kundenservice und Qualität sind uns wichtig. Als Komplexdienstleiter kümmern wir uns um Arbeiten von Transporten,
                            Kranarbeiten, Winterdienste, Baustoffhandel bis zum Bauschuttmulden Service. Wir versuchen alles für Sie möglich zu
                            machen. Haben Sie eine spezielle Anfrage? Dann zögern Sie nicht, sich mit uns in Kontakt zu setzen. Wir freuen uns auf die
                            Zusammenarbeit mit Ihnen!
                        </Paragraph>
                    </TextContainer>
                    <Image src={Alex} alt="Alexander Krempler" />
                </Content>
            </Layout>
            <Contact />
        </>
    );
};

export default AboutUs;

const Content = styled.div`
    grid-column: 3 / 23;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        flex-direction: row;
    }
`;

const Image = styled.img`
    display: none;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        display: block;
    }
`;

const Headline = styled.h1`
    ${({ theme }) => theme.fonts.headline};
    margin-top: 0;
    margin-bottom: 20px;
`;

const Paragraph = styled.p`
    ${({ theme }) => theme.fonts.paragraph};
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        width: 60%;
    }
`;

const SubHeadline = styled.h2`
    ${({ theme }) => theme.fonts.headline3};
`;
