import { Layout } from "common";
import Background from "assets/aboutus.webp";
import Alex from "assets/alex.webp";
import useDeviceDetect, { DeviceType } from "hooks/device";

const Stage: React.FC = () => {
    const device = useDeviceDetect();
    return (
        <Layout minHeight="55vh" minHeightMobile="55vh" background={device === DeviceType.MOBILE ? `url(${Alex})` : `url(${Background})`}></Layout>
    );
};

export default Stage;
