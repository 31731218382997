import React from "react";
import { Contact } from "common";
import Services from "./services";
import Projects from "./projects";
import Stage from "./stage";

const Home: React.FC = () => {
    return (
        <>
            <Stage />
            <Services />
            <Projects />
            <Contact />
        </>
    );
};

export default Home;
