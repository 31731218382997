import React from "react";
import styled from "styled-components";
import { Phone } from "@mui/icons-material";
import ConstructionIcon from "@mui/icons-material/Construction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FieldValues, useForm } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { Layout, Maps } from "common";
import { MobileView } from "./device";

const Contact: React.FC = () => {
    const [captchaValue, setCaptchaValue] = React.useState<string | null>(null);
    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitSuccessful },
    } = useForm({ defaultValues: { topic: "", name: "", message: "", email: "" } });

    const onSubmit = async (data: FieldValues) => {
        await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
            {
                subject: data["topic"],
                from_name: data["name"],
                message: data["message"],
                reply_to: data["email"],
                from_email: data["email"],
                "g-recaptcha-response": captchaValue,
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY || "",
        );
        reset({ topic: "", name: "", message: "", email: "" });
    };
    return (
        <>
            <Layout minHeight="100vh">
                <Background />
                <LeftArea>
                    <CallUsCard>
                        <Headline>
                            <ConstructionIcon /> Wann?
                        </Headline>
                        <Paragraph>
                            Montag - Samstag:{" "}
                            <MobileView>
                                <br />
                            </MobileView>
                            07:00 - 15:00Uhr <br />
                            Sonn- und Feiertag geschlossen
                        </Paragraph>
                        <Headline>
                            <Phone /> Wie?
                        </Headline>
                        <Paragraph>
                            <a href="tel:+436645490464">Tel.: +43 (0) 664 5490464</a> <br />
                            <a href="mailto:krempler.alexander@gmail.com">krempler.alexander@gmail.com</a>
                            <br />
                        </Paragraph>
                        <Headline>
                            <LocationOnIcon /> Wo?
                        </Headline>
                        <a href="https://maps.app.goo.gl/QREQ2xER1fAZKcdj6" target="_blank" rel="noreferrer">
                            <Container>
                                <Paragraph>
                                    Walchenstrasse 2
                                    <br />
                                    5204 Straßwalchen
                                    <br />
                                    Salzburg - Österreich
                                </Paragraph>
                            </Container>
                        </a>
                    </CallUsCard>
                </LeftArea>
                <RightArea>
                    <CallUsCard>
                        <Headline>
                            Interesse geweckt? <br /> Kontaktieren Sie uns!
                        </Headline>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <FormInput required {...register("name")} gridarea="top1" label="Name" />
                            <FormInput required {...register("email")} gridarea="top2" label="E-Mail" />
                            <FormInput required {...register("topic")} gridarea="mid1" label="Thema" />
                            <FormInput required {...register("message")} multiline gridarea="mid2" placeholder="Nachricht" rows={7} />
                            <ReCAPTCHA
                                style={{
                                    gridArea: "captcha",
                                    margin: "5px 0",
                                }}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : ""}
                                onChange={(value) => setCaptchaValue(value)}
                            />
                            <FormButton
                                variant="contained"
                                disabled={!(captchaValue !== null && captchaValue.length > 0)}
                                type="submit"
                                gridarea="bottom"
                            >
                                Absenden
                            </FormButton>
                        </Form>
                    </CallUsCard>
                    <Message show={isSubmitSuccessful}>Vielen Dank für ihr Interesse. Die E-Mail wurde erfolgreich versendet!</Message>
                </RightArea>
            </Layout>
            <Maps />
        </>
    );
};

export default Contact;

const Background = styled.div`
    z-index: 0;
    position: absolute;
    clip-path: polygon(0 0, 100% 71%, 100% 100%, 0% 100%);
    background-color: rgba(35, 95, 149, 70%);
    height: 100vh;
    width: 100%;
    display: none;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        display: block;
    }
`;

const Container = styled.div`
    color: ${({ theme }) => theme.palette.black};
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 10px;

    svg {
        font-size: 50px;
        color: ${({ theme }) => theme.palette.blue} !important;
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.black};
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }

    a,
    p {
        line-height: 25px;
    }
`;

const LeftArea = styled.div`
    align-self: center;
    grid-column: 2 / span 22;
    width: 100%;
    z-index: 5;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        grid-column: 3 / span 7;
    }
`;

const RightArea = styled.div`
    align-self: center;
    grid-column: 2 / span 22;
    z-index: 5;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        grid-column: 13 / span 10;
    }
`;

const CallUsCard = styled.div`
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    margin: 30px 0;
    padding: 32px;
    background-color: white;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        padding: 40px;
    }
`;

const Headline = styled.h2`
    ${({ theme }) => theme.fonts.headline2}
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        font-size: 30px;
        margin-right: 10px;
        color: ${({ theme }) => theme.palette.blue} !important;
    }

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
        flex-direction: row;
        text-align: left;
    }
`;

const Form = styled.form`
    box-shadow: none !important;
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
        display: grid;
        gap: 36px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "top1 top2"
            "mid1 mid1"
            "mid2 mid2"
            "captcha captcha"
            "bottom bottom"
            "message message";
    }
`;

interface FormInputProps {
    gridarea: string;
}

const FormInput = styled(TextField)<FormInputProps>`
    grid-area: ${({ gridarea }) => gridarea};
    padding: 12px 24px;
    margin: 10px 0 !important;
    background: ${({ theme }) => theme.palette.grey2};

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
        margin: 0 !important;
    }
`;

interface ButtonProps {
    gridarea: string;
}

const FormButton = styled(Button)<ButtonProps>`
    grid-area: ${({ gridarea }) => gridarea};
    ${({ theme }) => theme.fonts.button};
    color: ${({ theme }) => theme.palette.white} !important;
    border-radius: 4px;

    &:disabled {
        background-color: ${({ theme }) => theme.palette.grey} !important;
    }
`;

interface MessageProps {
    show: boolean;
}

const Message = styled.p<MessageProps>`
    grid-area: "message";
    width: 100%;
    text-align: center;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transition: 0.5s linear opacity;
    color: #77dd77;
    margin: 30px 0;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
        margin: 0;
    }
`;

const Paragraph = styled.div`
    ${({ theme }) => theme.fonts.paragraph};
    text-align: center;

    a {
        color: ${({ theme }) => theme.palette.blue} !important;
    }

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
        text-align: left;
    }
`;
