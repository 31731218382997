import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Home, AboutUs, Projects, AGB, DataSecurity, Impressum } from "pages";
import { Header, Footer } from "common";
import ScrollToTop from "utils/scrollToTop";

const App: React.FC = () => {
    return (
        <Router>
            <Header />
            <ScrollToTop>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/agb" element={<AGB />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/data-security" element={<DataSecurity />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </ScrollToTop>
            <Footer />
        </Router>
    );
};

export default App;
