import React from "react";
import styled from "styled-components";

interface LayoutProps {
    children?: React.ReactNode;
    minHeight?: string;
    minHeightMobile?: string;
    background?: string;
    padding?: string;
    paddingMobile?: string;
    position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
    positionMobile?: "static" | "relative" | "absolute" | "sticky" | "fixed";
    darkGradient?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
    children,
    minHeight,
    background,
    position,
    positionMobile,
    minHeightMobile,
    padding,
    darkGradient,
    paddingMobile,
}) => {
    return (
        <Container
            minHeight={minHeight}
            padding={padding}
            minHeightMobile={minHeightMobile}
            position={position}
            positionMobile={positionMobile}
            darkGradient={darkGradient}
            background={background}
            paddingMobile={paddingMobile}
        >
            {children}
        </Container>
    );
};

export default Layout;

interface ContainerProps {
    minHeight?: string;
    background?: string;
    position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
    positionMobile?: "static" | "relative" | "absolute" | "sticky" | "fixed";
    minHeightMobile?: string;
    padding?: string;
    darkGradient?: boolean;
    paddingMobile?: string;
}

const Container = styled.div<ContainerProps>`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    background: ${({ theme, background, darkGradient }) => {
        if (darkGradient && background) {
            return `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), ${background}`;
        } else if (background) {
            return background;
        } else {
            return theme.palette.white;
        }
    }};
    top: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    z-index: ${({ position }) => (position === "absolute" || position === "sticky" ? 1 : "inherit")};
    padding: ${({ paddingMobile }) => (paddingMobile ? paddingMobile : 0)};
    position: ${({ positionMobile }) => (positionMobile ? positionMobile : "relative")};
    min-height: ${({ minHeightMobile }) => (minHeightMobile ? minHeightMobile : "100vh")};

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        padding: ${({ padding }) => (padding ? padding : 0)};
        position: ${({ position }) => (position ? position : "relative")};
        min-height: ${({ minHeight }) => (minHeight ? minHeight : "100vh")};
    }

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.xxl}px`}) {
        min-height: ${({ minHeight }) => (minHeight ? `calc(${minHeight} / 2)` : "50vh")};
    }
`;
