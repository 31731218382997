import styled from "styled-components";

export const BrowserView = styled.div`
    display: none;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        display: block;
    }
`;

export const MobileView = styled.div`
    display: block;
    grid-column: 1 / span 23;
    place-self: center center;

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        display: none;
    }
`;
