import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface ServiceItemProps {
    img: string;
    service: string;
    link?: string;
    minWidthMobile?: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ img, link, service, minWidthMobile }) => {
    let navigate = useNavigate();

    return (
        <SlideContent
            minWidthMobile={minWidthMobile}
            isClickable={link ? true : false}
            backgroundImg={img}
            onClick={() => link && navigate(link, { replace: true })}
        >
            <SlideContentContainer>
                <SlideHeadline>{service}</SlideHeadline>
            </SlideContentContainer>
        </SlideContent>
    );
};

export default ServiceItem;

const SlideContentContainer = styled.div`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    display: flex;
`;

interface SlideContentProps {
    backgroundImg: string;
    isClickable: boolean;
    minWidthMobile?: string;
}

const SlideContent = styled.div<SlideContentProps>`
    width: ${({ minWidthMobile }) => (minWidthMobile ? minWidthMobile : "100%")};
    height: calc(100vh * 0.25);
    background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), ${({ backgroundImg }) => `url(${backgroundImg})`};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "normal")};

    @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
        width: 100%;
    }
`;

const SlideHeadline = styled.h2`
    ${({ theme }) => theme.fonts.headline2};
    margin: 0;
    color: ${({ theme }) => theme.palette.white};
    text-align: center;
`;
